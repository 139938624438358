import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom'
import { getNFTListByUserAction, cancelOrderAction, putOnSaleAction, filterUserNFTsAction, getLandListUserAction, getfilterLandListUserAction, getNFTLicenseByUserAction, getfilterLicenseByUserAction, getSafeNFTByUserAction, getfilterSafeNFTByUserAction, getLandgroupDetailsAction, updateLandgroupAction, getLandgroupAllNFTsDetailsAction, getlandroadsAction, getglobalroyalityAction, CreateGroupAction, putgroupItemOnSaleAction,getcartcountAction } from '../Action/user.action';
import config from '../config';
import toast, { Toaster } from 'react-hot-toast';
import Web3 from 'web3';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import DamageRating from '../components/layouts/DamageRating';
import AccuracyRating from '../components/layouts/AccuracyRating';
import RateRating from '../components/layouts/RateRating';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Portfolio = () => {
    let subtitle;
    const [cartcount, setcartcount] = useState(0);
    const [NFTList, setNFTList] = useState([]);
    const [FilterNFTList, setFilterNFTList] = useState([]);
    const [LandList, setLandList] = useState([]);
    const [LandGroupList, setLandGroupList] = useState([]);
    const [LicenseList, setLicenseList] = useState([]);
    const [SafeNFTList, setSafeNFTList] = useState([]);
    
    const [VIPNFTList, setVIPNFTList] = useState([]);
    const [MTONFTList, setMTONFTList] = useState([]);
    
    const [FilterVIPNFTList, setFilterVIPNFTList] = useState([]);
    const [FilterMTONFTList, setFilterMTONFTList] = useState([]);
    
    const [isPutonsale, setisPutonsale] = useState(0);   
    const [issafe, setissafe] = useState(1);
    const [itemDetails, setItemDetails] = useState([]);
    const [SearchSFilter, setSearchSFilter] = useState('');
    const [spinLoader, setSpinLoader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [blockchainUpdationType, setblockchainUpdationType] = useState(0);
    const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));
    const [walletAddress, setwalletAddress] = useState('');
    const [modalopen, setModalopen] = useState(0);   
    const [textboxes, setTextboxes] = useState([]);   
    const [validatioError, setvalidatioError] = useState({});  
    const [globalroyality, setglobalroyality] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);  
    const [safeLoader, setsafeLoader] = useState(1);
    const [isCreatGroup, setCreatGroup] = useState(0);
    
    useEffect(async () => {	
    localStorage.setItem('lastVisitedPage', 'portfolio'); 	
		getglobalroyalityAPI();  
		  if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                
                if (accounts) {
                    setwalletAddress(accounts[0]);
                    getSafeNFTListAPI(accounts[0]);
                    getcartcountAPI(accounts[0]);
                }
            })
        }

        setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
				getSafeNFTListAPI(ethereum.selectedAddress);
                 setwalletAddress(ethereum.selectedAddress);
                 getcartcountAPI(ethereum.selectedAddress);
            } else {
                getSafeNFTListAPI([]);
            }
        }, 100);
        
        		
		const handleResize = () => {
		  setWindowWidth(window.innerWidth);
		};

		// Add event listener to track window width changes
		window.addEventListener('resize', handleResize);

	// Cleanup the event listener on component unmount
	return () => {
	  window.removeEventListener('resize', handleResize);
	};
    }, []);

    function afterOpenModal() {
        
    }
    
     const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
     const getglobalroyalityAPI = async () => {
        let res = await getglobalroyalityAction();
        if (res.success) {
            setglobalroyality(res.data)
        }
    }
    
    
    const EditNFTDetails = async (id) => {
		setTimeout(() => {
			window.location.href = `${config.baseUrl}edit-nft/`+id;
		}, 1000);
	}
   
    const getSafeNFTListAPI = async (wallet) => {
		 setsafeLoader(1)		
		let res = await getNFTListByUserAction({'walletAddress' : wallet,'nft_type' :config.SAFE });
        if (res.success) {
			let AllSafenfts = res.data;
			const result = AllSafenfts.filter(data => parseInt(data.item_sub_category_id) == 0 ); 			
            setSafeNFTList(result);
            setFilterNFTList(result)
            
			const vipresult = AllSafenfts.filter(data =>  parseInt(data.item_sub_category_id) ==  parseInt(config.VIP)); 			
            setVIPNFTList(vipresult);
            setFilterVIPNFTList(vipresult)
            
			const TToOresult = AllSafenfts.filter(data =>  parseInt(data.item_sub_category_id) ==  parseInt(config.TToO)); 			
            setMTONFTList(TToOresult);
            setFilterMTONFTList(TToOresult)
            
        }
        setsafeLoader(0)
        
    }  
     

    const putOnSaleModelAPI = async (item) => {
        setisPutonsale(1);
        setItemDetails(item);
    }
     const closebutton = async () => {
        setModalopen(0);
    }
  
    const showsafenfts = async () => {
		getSafeNFTListAPI(walletAddress)             
        setissafe(1);  
    }    
    const closeModel = async () => {
        setisPutonsale(0);
        setCreatGroup(0);
    }
	const approveNFT = async (itemDetails, cancelType = 0) => {
		 setSpinLoader(1);
		if(cancelType==1)
		{
			closeModel();
		}
        if (window.ethereum) {
            let web3 = '';
            web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();

            let walletAdd = accounts[0];
            if (!walletAdd) {
                toast.error('Please connect your metamask wallet.');
                return;
            } else if (itemDetails.owner_address && walletAdd.toUpperCase() !== itemDetails.owner_address.toUpperCase()) {
                toast.error(`Please select (${itemDetails?.owner_address.substring(0, 8) + '...' + itemDetails?.owner_address.substr(itemDetails?.owner_address.length - 8)}) address to your metamask wallet.`);
                return;
            }
            let from_address = accounts[0];
            var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
            var currentBal = parseFloat(getBalace).toFixed(6)
            if (currentBal == 0) {
                toast.error(`Insufficient fund!!`);
                return false;
            }

            let currentNetwork = await web3.currentProvider.chainId;
            web3.eth.defaultAccount = accounts[0];
            let chainId = config.chainId;
            if (currentNetwork !== chainId) {
                toast.error('Please select BNB smartchain!!');
                return false;
            }
             //  Approve transaction
                const contractForApprove = await new web3.eth.Contract(config.nftContractABI, itemDetails?.contractAddress);
                let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();

                if (isApproved == false) {
					try {
						let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);

						setSpinLoader(1);
						setDialogOpen(true);		
						let encodedTxForToken = tx_builderForToken.encodeABI();
						let gasPriceForToken = await web3.eth.getGasPrice();

						let gasLimitForToken = await web3.eth.estimateGas({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});

						await web3.eth.sendTransaction({
							gasPrice: web3.utils.toHex(gasPriceForToken),
							gas: web3.utils.toHex(gasLimitForToken),
							to: itemDetails?.contractAddress,
							from: from_address,
							chainId: chainId,
							data: encodedTxForToken
						});
						
                }
                catch (err) {
				   if (err.message.toString().split('insufficient funds')[1]) {
						toast.error('Transaction reverted : ' + err.message)
					} else {
						if (err.toString().split('execution reverted:')[1]) {
							toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

						} else {
							if (err.toString().split('execution reverted:')[1]) {
								toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
							} else {
								toast.error(err.message);
							}
						}
					}

					setSpinLoader(0);
					setisPutonsale(0);
					setDialogOpen(false);
					if(itemDetails.nft_type==config.LAND)
					{
						 setTimeout(() => {
							window.location.href = `${config.baseUrl}portfolio-other`;
						}, 3000);
					}
					else
					{
						setTimeout(() => {
							window.location.href = `${config.baseUrl}portfolio-other`;
						}, 3000);
					}
					//return false;
				}
			}		
            setSpinLoader(1);
            setisPutonsale(0);
            setDialogOpen(true);
            
            try {
                let mintFee = 0;
                let SalePrice;
                let start_date = 0;
                let expiry_date = 0;
                let tokenId = itemDetails.token_id;
                let royalty_percent = parseInt(parseInt(itemDetails?.royalty_percent) * 100);

                if (itemDetails.sell_type == 1) {
                    SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                }

                else if (itemDetails.sell_type == 2) {
                    SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');       
                    start_date = Math.round(new Date(itemDetails.start_date).getTime() / 1000);
                    expiry_date = Math.round(new Date(itemDetails.expiry_date).getTime() / 1000);
                }
				
                let contractAddress = `${config.mainMarketplaceContract}`
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);

                let nftContractAddress = `${config.nftContract}`
                const nftContract = await new web3.eth.Contract(config.nftContractABI, nftContractAddress);
				
                if (cancelType == 1) {
                    setblockchainUpdationType(2)
                    await contract.methods.cancelOrder(itemDetails?.contractAddress.toString(), tokenId.toString()).call();
                    var tx_builder = await contract.methods.cancelOrder(itemDetails?.contractAddress.toString(), tokenId.toString());
                } else {
                    setblockchainUpdationType(1)
                    if (itemDetails.is_minted == 1) {
                   
                        await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), itemDetails?.contractAddress.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() ).call();

                        var tx_builder = await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), itemDetails?.contractAddress.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() );
                    } else {
                        await nftContract.methods._mint(tokenId.toString()).call();
                        var tx_builder = await nftContract.methods._mint(tokenId.toString());

                        let encoded_tx = tx_builder.encodeABI();
                        let gasPrice = await web3.eth.getGasPrice();
                        gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                        let gasLimit = await web3.eth.estimateGas({
                            gasPrice: web3.utils.toHex(gasPrice),
                            to: itemDetails?.is_minted == 1 ? contractAddress : nftContractAddress,
                            from: from_address,
                            value: web3.utils.toHex(mintFee),
                            chainId: chainId,
                            data: encoded_tx
                        });

                        const txData = await web3.eth.sendTransaction({
                            gasPrice: web3.utils.toHex(gasPrice),
                            gas: web3.utils.toHex(gasLimit),
                            to: itemDetails?.is_minted == 1 ? contractAddress : nftContractAddress,
                            from: from_address,
                            value: web3.utils.toHex(mintFee),
                            chainId: chainId,
                            data: encoded_tx
                        });

                        if (txData.transactionHash) {

                            //  Approve transaction
                            const contractForApprove = await new web3.eth.Contract(config.nftContractABI, config.nftContract);
                            let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();
							
                            if (isApproved == false) {
                                let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);
							
                                setSpinLoader(1);
                                setDialogOpen(true);

                                let encodedTxForToken = tx_builderForToken.encodeABI();
                                let gasPriceForToken = await web3.eth.getGasPrice();

                                let gasLimitForToken = await web3.eth.estimateGas({
                                    gasPrice: web3.utils.toHex(gasPriceForToken),
                                    to: config.nftContract,
                                    from: from_address,
                                    chainId: chainId,
                                    data: encodedTxForToken
                                });

                                await web3.eth.sendTransaction({
                                    gasPrice: web3.utils.toHex(gasPriceForToken),
                                    gas: web3.utils.toHex(gasLimitForToken),
                                    to: config.nftContract,
                                    from: from_address,
                                    chainId: chainId,
                                    data: encodedTxForToken
                                });
                            }

                            await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), config.nftContract.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() ).call();

                            var tx_builder = await contract.methods.putonSale(tokenId.toString(), SalePrice.toString(), config.nftContract.toString(), itemDetails.sell_type.toString(), start_date.toString(), expiry_date.toString(), royalty_percent.toString() );
                        }
                    }
                }

                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gas: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    value: web3.utils.toHex(mintFee),
                    chainId: chainId,
                    data: encoded_tx
                });

                if (txData.transactionHash) {                
                    let dataArr = {                        
                        "wallet_address": from_address,
                        "user_id": loginData.id,
                        "item_id": itemDetails.id,
                        "token_hash": txData.transactionHash
                    }
                  
                    let res;
                    if (cancelType) {
                        res = await cancelOrderAction(dataArr);
                    } else {
                        res = await putOnSaleAction(dataArr);
                    }
                    if (res.success === true) {
                        setDialogOpen(false);                      
                        toast.success(res.msg);
                        setTimeout(() => {
							window.location.href = `${config.baseUrl}portfolio-other`;
						}, 3000);
                    } else {
                        toast.error(res.msg);
                    }
                } else {
                    toast.error('Something went wrong please try again.');
                    setSpinLoader(0);
                    setisPutonsale(0);
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
               if (err.message.toString().split('insufficient funds')[1]) {
                    toast.error('Transaction reverted : ' + err.message)
                } else {
                    if (err.toString().split('execution reverted:')[1]) {
                        toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                    } else {
                        if (err.toString().split('execution reverted:')[1]) {
                            toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                        } else {
                            toast.error(err.message);
                        }
                    }
                }

                setSpinLoader(0);
                setisPutonsale(0);
                setDialogOpen(false);
                setTimeout(() => {
					window.location.href = `${config.baseUrl}portfolio-other`;
				}, 3000);
				
            }
        } else {
            toast.error('Please connect your metamask wallet.');
            setSpinLoader(0);
            setisPutonsale(0);
            setDialogOpen(false);
            return false;
        }
    }
	
	const SetSNameFilter = async (e) => {
	  setsafeLoader(1)	
      setSearchSFilter(e.target.value);       
      const searchText = e.target.value.toLowerCase();
      console.log(e.target.value)
      if(searchText != null){
		const result = SafeNFTList.filter(data => data.name.toLowerCase().includes(searchText) || data.token_id.includes(searchText)); 
		setFilterNFTList(result); 
		const vipresult = VIPNFTList.filter(data => data.name.toLowerCase().includes(searchText) || data.token_id.includes(searchText)); 
		setFilterVIPNFTList(vipresult); 
		
		const mtoresult = MTONFTList.filter(data => data.name.toLowerCase().includes(searchText) || data.token_id.includes(searchText)); 
		setFilterMTONFTList(mtoresult); 
		setsafeLoader(0)   
	  }
	  else
		{
			 console.log('els',e.target.value)
			 setFilterNFTList(SafeNFTList);
			 setFilterVIPNFTList(VIPNFTList);
			 setFilterMTONFTList(MTONFTList);  
			 setsafeLoader(0)   
		} 
          
    }
    
	const handleSKeyPress = async (event) => {
		if(event.key === "Enter"){
			setsafeLoader(1)
			if(SearchSFilter != null)
			{
			  const result = SafeNFTList.filter(data => data.name.toLowerCase().includes(SearchSFilter) || data.token_id.includes(SearchSFilter)); 
			  setFilterNFTList(result); 
			  
			  const vipresult = VIPNFTList.filter(data => data.name.toLowerCase().includes(SearchSFilter) || data.token_id.includes(SearchSFilter)); 
			  setFilterVIPNFTList(vipresult); 
				
			  const mtoresult = MTONFTList.filter(data => data.name.toLowerCase().includes(SearchSFilter) || data.token_id.includes(SearchSFilter)); 
			  setFilterMTONFTList(mtoresult); 
			  setsafeLoader(0)   
			}
			else
			{
				setFilterNFTList(SafeNFTList); 
				setFilterVIPNFTList(VIPNFTList);
			    setFilterMTONFTList(MTONFTList);  
				setsafeLoader(0)   
			}
     }
 }
    
    const cancelNftOrder = async (item) => {
        approveNFT(item, 1)       
    }

const otherNFTs = issafe === 1 && safeLoader === 0 && FilterNFTList.length > 0 && FilterNFTList.map((safeitem) => (				  
					 <div className="col-lg-4 col-md-6">
						<div className="tab-section-main">								
							<div className="images-sec">
							<Link to={`${config.baseUrl}nft-details/` + safeitem.id}>
								<img src={`${config.ipfsUrl + safeitem.image}`} alt="Axies" />
								</Link>
							
							</div>
							<div className="section-map">
								<h3><Link to={`${config.baseUrl}nft-details/` + safeitem.id}>
								{
								  windowWidth < 1299 ?						
									safeitem.name.toString().substring(0, 15) + '...'
									:
									safeitem.name.toString().substring(0, 26) +
									(safeitem.name.length > 26 ? '...' : '')
								}
								</Link></h3>
							</div>
							<div className="price-section">
								<div className="price">
									<p>Price</p>
									<h3>{parseInt(safeitem.price).toLocaleString('en-US')} SIN</h3>
								</div> 
								<div className="price">
									<p>Token ID</p>
									<h3>#{safeitem.token_id}</h3>
								</div>								
							</div>
							<div className="View-History flx">
							{ safeitem.is_on_sale == 1 ?
								<button onClick={ () => { cancelNftOrder(safeitem, 1) } } className='tab-btn' data-toggle="modal" data-target="#putOnSale">{ safeitem.not_owner &&  safeitem.not_owner==1 ? 'Cancel Listing': 'Cancel Listing'}</button>
								:
								<>
									<button onClick={() => { putOnSaleModelAPI(safeitem) } } className='tab-btn' data-toggle="modal" data-target="#putOnSale">Put On Sale</button>

									&nbsp;
									<Link to={`${config.baseUrl}edit-nft/` + safeitem.id} >
										<button className='tab-btn'>Edit</button>
									</Link>
								</>
								  }
							
							</div>
						</div>
					</div>			
					));

const vipNFTs = issafe === 1 && safeLoader === 0 && FilterVIPNFTList.length > 0 && FilterVIPNFTList.map((safeitem) => (				  
					 <div className="col-lg-4 col-md-6">
						<div className="tab-section-main">								
							<div className="images-sec">
							<Link to={`${config.baseUrl}nft-details/` + safeitem.id}>
								<img src={`${config.ipfsUrl + safeitem.image}`} alt="Axies" />
								</Link>
							
							</div>
							<div className="section-map">
								<h3><Link to={`${config.baseUrl}nft-details/` + safeitem.id}>
								{
								  windowWidth < 1299 ?						
									safeitem.name.toString().substring(0, 15) + '...'
									:
									safeitem.name.toString().substring(0, 26) +
									(safeitem.name.length > 26 ? '...' : '')
								}
								</Link></h3>
							</div>
							<div className="price-section">
								<div className="price">
									<p>Price</p>
									<h3>{parseInt(safeitem.price).toLocaleString('en-US')} SIN</h3>
								</div> 
								<div className="price">
									<p>Token ID</p>
									<h3>#{safeitem.token_id}</h3>
								</div>								
							</div>
							<div className="View-History flx">
							{ safeitem.is_on_sale == 1 ?
								<button onClick={ () => { cancelNftOrder(safeitem, 1) } } className='tab-btn' data-toggle="modal" data-target="#putOnSale">{ safeitem.not_owner &&  safeitem.not_owner==1 ? 'Cancel Listing': 'Cancel Listing'}</button>
								:
								<>
									<button onClick={() => { putOnSaleModelAPI(safeitem) } } className='tab-btn' data-toggle="modal" data-target="#putOnSale">Put On Sale</button>

									&nbsp;
									<Link to={`${config.baseUrl}edit-nft/` + safeitem.id} >
										<button className='tab-btn'>Edit</button>
									</Link>
								</>
								  }
							
							</div>
						</div>
					</div>			
					));

const ttoNFTs = issafe === 1 && safeLoader === 0 && FilterMTONFTList.length > 0 && FilterMTONFTList.map((safeitem) => (				  
					 <div className="col-lg-4 col-md-6">
						<div className="tab-section-main">								
							<div className="images-sec">
							<Link to={`${config.baseUrl}nft-details/` + safeitem.id}>
								<img src={`${config.ipfsUrl + safeitem.image}`} alt="Axies" />
								</Link>
							
							</div>
							<div className="section-map">
								<h3><Link to={`${config.baseUrl}nft-details/` + safeitem.id}>
								{
								  windowWidth < 1299 ?						
									safeitem.name.toString().substring(0, 15) + '...'
									:
									safeitem.name.toString().substring(0, 26) +
									(safeitem.name.length > 26 ? '...' : '')
								}
								</Link></h3>
							</div>
							<div className="price-section">
								<div className="price">
									<p>Price</p>
									<h3>{parseInt(safeitem.price).toLocaleString('en-US')} SIN</h3>
								</div> 
								<div className="price">
									<p>Token ID</p>
									<h3>#{safeitem.token_id}</h3>
								</div>								
							</div>
							<div className="View-History flx">
							{ safeitem.is_on_sale == 1 ?
								<button onClick={ () => { cancelNftOrder(safeitem, 1) } } className='tab-btn' data-toggle="modal" data-target="#putOnSale">{ safeitem.not_owner &&  safeitem.not_owner==1 ? 'Cancel Listing': 'Cancel Listing'}</button>
								:
								<>
									<button onClick={() => { putOnSaleModelAPI(safeitem) } } className='tab-btn' data-toggle="modal" data-target="#putOnSale">Put On Sale</button>

									&nbsp;
									<Link to={`${config.baseUrl}edit-nft/` + safeitem.id} >
										<button className='tab-btn'>Edit</button>
									</Link>
								</>
								  }
							
							</div>
						</div>
					</div>			
					)); 
    return (
        <div className='auctions'>
            <Toaster />

            <Modal
                isOpen={isDialogOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="text-center pl-3 pr-3">
                    < br />
                    {blockchainUpdationType == 1 ?
                        <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                            Put on sale in progress, once the process is completed, the NFT will be displayed on the marketplace page.
                        </h4>
                        :
                        blockchainUpdationType == 2 ?
                            <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                Canceling your listing will unpublish this sale from Sinverse and requires a transaction.
                            </h4>
                            :
                            <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                Bid accepting in progress, Please wait for a while.
                            </h4>
                    }

                    <p style={{ color: '#091f3f' }}>
                       Please do not refresh the page or close the tab.
                    </p>
                    <div>
                        <img src="images/loader.gif" height={50} width={50} />
                    </div>
                </div>
            </Modal>

            <Header data={{'cartcount':cartcount}} />
            <div className="market-place-banner">
			  <h1>Portfolio</h1>
			</div>
			<div className="market-place">
			<div className="container">
			<div className="row">
			  <div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				  <ul>
					<li><Link to={`${config.baseUrl}portfolio-weapon`} className="">Weapon & Armor Collections</Link></li>
					<li><Link to={`${config.baseUrl}portfolio-land`} className="">Land</Link></li>
					<li><Link to={`${config.baseUrl}portfolio-license`} className="">License</Link></li>
					<li className="active"><a href="javascript:;" onClick={() => { showsafenfts() }}>Other NFTs</a></li>	
					<li className="d-none"><Link to={`${config.baseUrl}mystery-loot-crates`} className="">Mystery Loot Crates</Link></li>
				</ul>
				</div>
			  </div>
			  <div className="col-12 col-md-9 col-lg-10 pl-4">
				<div className="row">
				{issafe === 1 ?  
				<>
				 <div className="col-12 d-flex pro-filter-outer">					
					<div className="pro-search">					  
							 <input type="text" id="search" className="form-control w210px" placeholder="Search (Title Or Token ID)" onChange={SetSNameFilter} name="s" title="Search for" value={SearchSFilter} onKeyDown={() => handleSKeyPress(this)} />
					</div>
				  </div>	
				 { FilterNFTList.length > 0 ?
					 <>	 		
					  <div className="col-12">
						<h2 className="title">Explore Other NFTs Collection</h2>
					  </div>
				  </>
				  :''
				}
				  <div className="col-12">
					  <p className="clr-wt">All the NFTs purchased from the marketplace or which are put to sale from Collected NFT section are shown here.</p>
					  </div>
					 {safeLoader==0 ?
						<>  
					   {otherNFTs}
					 
					 { FilterVIPNFTList.length > 0 ?
						 <>   
						  <div className="col-12 mt-5">
							<h2 className="title">Explore VIP NFTs Collection</h2>
						  </div>
						</>
						: ''
					}	
					   {vipNFTs}
					{ FilterMTONFTList.length > 0 ? 
						<>   
							<div className="col-12 mt-5">
							<h2 className="title">Explore TToO Membership NFTs Collection</h2>
							</div>
						</>
						: ''
					}
					   {ttoNFTs}
					  </> 
						:
						(
						 <div className="loaderDiv">
						  <img className='loaderImg' src="images/loader.gif" height={50} width={50} />
						  </div>
						 )
					}	
					</>
					: ''
				}
						
							
						
					</div>
					</div>
					</div>
					</div>
					</div>
            {/* Put on sale model */}
            {isPutonsale === 1 ?
				<>
            <div className={isPutonsale === 0 ? "modal fade" : "modal fade show"} id="putOnSale" style={{ display: isPutonsale === 0 ? 'none' : 'block' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" data-id={isPutonsale}>
                <div className="modal-dialog" role="document">
					{isCreatGroup === 0 ?
						<>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Put On Sale </h5>
                            <a type="button" className="close" data-dismiss="modal" style={{
                                fontSize: '26px'
                            }} aria-label="Close" onClick={closeModel} >
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>

                        <div className="modal-body">
                            <div className="de_tab tab_methods">
                                <div className="de_tab_content">
                                    <span style={{ color: 'white' }}>List price and listing schedule can not be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price. </span><br /><br />
                                    {itemDetails?.sell_type === 1 ?
                                        <>
                                            <h5>Price: {parseInt(itemDetails?.price).toLocaleString('en-US')} SIN</h5>
                                        </>
                                        :
                                        itemDetails?.sell_type === 2 ?
                                            <>
                                                <div className="row" style={{ lineHeight: '22px' }}>
                                                    <div className="col-md-6">
                                                        <h5>NFT Type</h5>
                                                        Auction
                                                    </div>

                                                    <div className="col-md-6">
                                                        <h5>Minimum bid</h5>
                                                        {itemDetails?.price} SIN
                                                    </div>
                                                </div>
                                                <br />

                                                <div className="spacer-10" />
                                                <div className="row" style={{ lineHeight: '22px' }}>
                                                    <div className="col-md-6">
                                                        <h5>Starting date</h5>
                                                        {itemDetails?.start_date ? itemDetails?.start_date : ''}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h5>Expiration date</h5>
                                                        {itemDetails?.expiry_date ? itemDetails?.expiry_date : ''}

                                                    </div>
                                                    <div className="spacer-single" />
                                                </div>
                                            </>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className="spacer-10 mt-10" />
                            {spinLoader == '0' ?
									<>
                                <input disabled={spinLoader ? true : false} type="submit"  onClick={() => { approveNFT(itemDetails) }} value={spinLoader == 1 ? 'Processing...' : `Approve`} id="submit" className="tab-btn" defaultValue="Create Item" />
                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <input type="button"  onClick={() => { EditNFTDetails(itemDetails.id) }} value="Edit" id="Edit" className="tab-btn crsr" defaultValue="Edit Item" />
                                </>
                                :
                                <button disabled className="tab-btn" id="deposit-page" >Processing &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
                            }
                            <div className="spacer-single" />
                        </div>
                    </div>
                     </>
                    :
                    ''
				}                
                </div>
            </div>
			</>
			: ''
		}
            
            <div id="myModal" className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered gridmodal">
                    <div className="modal-content">
						<button type="button" onClick={closebutton} className="close btnClose" data-dismiss="modal">&times;</button>                                      
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">                                        
                                        <div class="graph text-center">
											<canvas id="myCanvas"></canvas>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <Footer />
        </div>
    );
}

export default Portfolio;
