import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import { getCityLicenseCountAction, getcityidAction, getcartcountAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";


const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));



const BuyLicense = () => {
	const { id } = useParams();
	const [cartcount, setcartcount] = useState(0);
	const [CityDetails, setCityDetails] = useState([]);
	const [WeedFarmCount, setWeedFarmCount] = useState(0);
	const [CofeeShopCount, setCofeeShopCount] = useState(0);
    const [walletAddress, setwalletAddress] = useState('');
   
    useEffect(async () => {
		 if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                setwalletAddress(accounts[0]);
				getCityWeedFarmCountAPI(accounts[0]); 
				getCityCoffeeCountAPI(accounts[0]);
				getcartcountAPI(accounts[0]);
            })
        }

        setTimeout(() => {
            if (window.ethereum) {
                 const { ethereum } = window;                
                setwalletAddress(ethereum.selectedAddress);
                getCityWeedFarmCountAPI(ethereum.selectedAddress); 
				getCityCoffeeCountAPI(ethereum.selectedAddress);
				getcartcountAPI(ethereum.selectedAddress);
            } else {
                getCityWeedFarmCountAPI(''); 
				getCityCoffeeCountAPI('');
            }
        }, 100);   
     getCityDetailsAPI();      

    }, []);    
    
     const navigate = useNavigate();

	  const handleGoBack = () => {
		navigate(-1); // Navigate back by one step in the history stack
	  };

   
     const getCityDetailsAPI = async () => {
        let res = await getcityidAction({ 'id': id });
        if (res.success) {
            setCityDetails(res.data);
        }
    }
    const getCityWeedFarmCountAPI = async (walletAddress) => {
        let res = await getCityLicenseCountAction({ 'city_id': id,'nft_type': 2,'walletAddress': walletAddress });
        if (res.success) {
            setWeedFarmCount(res.data);
        }
    }
    const getCityCoffeeCountAPI = async (walletAddress) => {
        let res = await getCityLicenseCountAction({ 'city_id': id,'nft_type': 3,'walletAddress': walletAddress});
        if (res.success) {
            setCofeeShopCount(res.data);
        }
    }
     
     const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }  
    
    return (
       <div>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            <div className="market-place-banner">
			  <h1>Buy License</h1>			  
			</div>
			<div className="market-place">
			<div className="container">
			<div className="row">
			  <div className="col-12 col-md-3 col-lg-2 px-0 ">
				<div className="left-sidebar">
				  <ul>
					<li><Link to={`${config.baseUrl}marketplace`} className="">Weapon & Armor Collections</Link></li>
					<li><Link to={`${config.baseUrl}land-marketplace`} className="">Land</Link></li>
					<li className="active"><Link to={`${config.baseUrl}licenses-cities`} className="">License</Link></li>					
					<li><Link to={`${config.baseUrl}othernfts`} className="">Other NFTs</Link></li>
					<li className="d-none"><Link to={`${config.baseUrl}mystery-loot-crates`} className="">Mystery Loot Crates</Link></li>			
				  </ul>
				</div>
			  </div>
			  <div className="col-12 col-md-9 col-lg-10 pl-4">
				<div className="row">
				 
				   <div className="col-12 back-btn">
                    <button className="custom-btn btn-bg-blue" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i>Back</button>
                    </div>
				  <div className="col-12">
					<h2 className="title">{CityDetails.title}</h2>
				  </div>
				  {CityDetails.id !== 3 && CityDetails.id !== 4 && CityDetails.id !== 6 ? (
									  <div className="col-md-6">
										<div className="tab-section-main shoptype">
										  <div className="images-sec">
										  {WeedFarmCount > 0 ? (
											 <Link to={`${config.baseUrl}weed-farm-list/` + id} className="w100">
												<img src="images/weedfarm.jpg" alt="Premium License" />
											 </Link>
											) : (
											<a href="javascript:;" className="w100">
											  <img src="images/weedfarm.jpg" alt="Premium License" />
											   </a>
											)}
											
										  </div>
										  <div className="section-pistol">
											<h3>Available Licenses: {WeedFarmCount}</h3>
										  </div>
										  <div className="price-section mob-pricee-section">
											<div className="price"></div>
											{WeedFarmCount > 0 ? (
											  <a href={`${config.baseUrl}weed-farm-list/` + id}>
												<button className="tab-btn">Buy Premium License</button>
											  </a>
											) : (
											  <button className="tab-btn">Out of stock</button>
											)}
										  </div>
										</div>
									  </div>
									) : (
									  ''
									)}
                                        <div className="col-md-6">
						<div className="tab-section-main shoptype">
							<div className="images-sec">
							 {CofeeShopCount > 0 ? (
								 <Link to={`${config.baseUrl}coffee-shop-list/` + id} className="w100">
									<img src="images/coffeshop.jpg" alt="Exclusive License" />	
								 </Link>
								) : (
								 <a href="javascript:;" className="w100">
								  <img src="images/coffeshop.jpg" alt="Exclusive License" />	
								   </a>
								)}
								
							</div>
							<div className="section-pistol">
								<h3>Available Licenses : {CofeeShopCount}</h3>
							</div>
							<div className="price-section mob-pricee-section">
								<div className="price"></div>
								{ CofeeShopCount > 0 ?	
									<a href={`${config.baseUrl}coffee-shop-list/` + id}>
										<button className="tab-btn">Buy Exclusive License </button>
									</a>
									:
									<button className="tab-btn">Out of stock</button>
									
								}
														
							</div>
							
							</div>
					</div>
					
					</div>
					</div>
					</div>
					</div>
					</div>
            <Footer />
        </div>
    );
}

export default BuyLicense;
