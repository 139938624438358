import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import toast, { Toaster } from 'react-hot-toast';
import 'react-tabs/style/react-tabs.css';
import ReactTooltip from 'react-tooltip';
import { ContactFormAction, buyLicenseAction, getLicenseAction,trnasferTokenAPIAction,  getNftHistoryAction, buyItemAction, validatelicenseAction, getcartcountAction } from '../Action/user.action';
import config from '../config';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import Swal from "sweetalert2";


const loginData = (!Cookies.get('loginSuccessSinverseUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessSinverseUserpanel'));



const BuyCoffeeLicense = () => {
	const { id } = useParams();
	const [cartcount, setcartcount] = useState(0);
	const [connectWalletAddress, setConnectWalletAddress] = useState('');
    const [sinToCoinValue, setSinToCoinValue] = useState('');
    const [getBalance, setBalance] = useState(0);//setTotalCount
    const [spinLoader, setSpinLoader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalopen, setModalopen] = useState(0);   
    const [buy, setbuy] = useState(0);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [NFTDetails, setNFTDetails] = useState([]);
    const [NFTHistory, setNFTHistory] = useState([]);
    const [isHistory, setHistory] = useState(0);
   
    useEffect(async () => {   
		setTimeout(() => {
            if (window.ethereum) {
                const { ethereum } = window;
                setConnectWalletAddress(ethereum.selectedAddress);
                getNFTDetailsAPI(ethereum.selectedAddress);
                getcartcountAPI(ethereum.selectedAddress);
            }
             else
            {
            getNFTDetailsAPI('');
            }
        }, 200);
     const hash = window.location.hash.substring(1);
	if(hash =='history')
	{
		setHistory(1);
	} 
    }, []);    
   
     const getNFTDetailsAPI = async (walletAddress) => {
        let res = await getLicenseAction({ 'id': id, 'licensetype': 2,'walletAddress': walletAddress  });
        if (res.success) {
            setNFTDetails(res.data);
             getNftHistoryAPI(id,walletAddress);
        }
         else
        {
			 Swal.fire({
					title: 'Alert',
					text: 'Sorry, All license are sold out.',
					icon: 'warning',
					confirmButtonColor: '#e7275a',
					background: '#343444',
					color: '#FFF',
					showCancelButton: false,
					allowOutsideClick:false,
					confirmButtonText: 'OK',
				  }).then((result) => {
					if (result.isConfirmed) {
					  // Redirect to the desired page
					 window.location.href= config.baseUrl+'licenses-cities';    
					}
				  });  
		}
    }
    const ShowHistory = () => {
        setHistory(1);
    }
    const HideHistory = () => {
        setHistory(0);
    }
    
    const navigate = useNavigate();

	  const handleGoBack = () => {
		navigate(-1); // Navigate back by one step in the history stack
	  };
    const getNftHistoryAPI = async (nft_id,walletAddress) => {
        let res = await getNftHistoryAction({ 'id': nft_id, 'walletAddress' : walletAddress  });
        if (res.success) {
            setNFTHistory(res.data);
        }
    }
    
    const getcartcountAPI = async (wallet) => {
        let res = await getcartcountAction({ 'user_id': loginData?.id, 'walletAddress' : wallet });
        if (res.success) {
            setcartcount(res.data);
        }
    }
    
      const connectMetasmask = async () => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setConnectWalletAddress(accounts);
            getNFTDetailsAPI(accounts);
        }
        else {
            toast.error(`Please use dApp browser to connect wallet!`);
        }
    }
    
     const validatelicense = async () => {
		let id 			= NFTDetails.id;
        let res = await validatelicenseAction({ 'id': id});
        if (res.success) {
            buylicense();
        }
        else
        {
			 toast.error(`Sorry, this license sold out.`);
			 setTimeout(() => {                
                         window.location.reload();               
                    }, 2000);
		}
    }
    
    
    const buylicense = async () => {
        if (!loginData?.id) {
             Swal.fire({  text: 'Please login first!!',
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
        } else {        
				let tokenId 	= NFTDetails.token_id;
				let id 			= NFTDetails.id;
				let tokenPrice 	= NFTDetails.price;	
				let contractAddress = NFTDetails.contractAddress;
				
							
				 if (window.ethereum) {
					const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
					let web3 = new Web3(window.ethereum);
					let currentNetwork = web3.currentProvider.chainId;
					let chainId = config.chainId;
					if (currentNetwork !== chainId) {
						Swal.fire({  text: 'Please select BNB network!',
							  title: "Alert",
							  confirmButtonText: 'Okay',
							  confirmButtonColor: '#e7275a',
							  background: '#343444',
							  color: '#FFF',
							  allowOutsideClick:false
							});
						return false;
					}
					try {
					
						 Swal.fire({
								title : "Processing...",
								text: 'Transaction is under process, please wait for confirmation!',
								allowEscapeKey: false,
								allowOutsideClick: false,
								showConfirmButton:false,
								background: '#343444',
								color: '#FFF'
							});
						let tx_builderForToken = '';
						let from_address = accounts[0];
						// Approve token	
							console.log(tokenPrice)					
							//let tokenValue = parseInt(parseFloat(tokenPrice) * (10 ** 18)).toString();
							let tokenValue = web3.utils.toWei(tokenPrice.toString(), 'ether')
							let tokenContractAddress = `${config.tokenContract}`;
							console.log(tokenValue)	
							const tokenContract = await new web3.eth.Contract(config.abiToken, tokenContractAddress);
							let allowance = await tokenContract.methods.allowance(from_address, config.mainMarketplaceContract).call();
							if (parseInt(allowance) < parseInt(tokenValue)) {
								tx_builderForToken = await tokenContract.methods.approve(config.mainMarketplaceContract.toString(), tokenValue.toString());
								
								setSpinLoader(1);
								setDialogOpen(true);

								let encodedTxForToken = tx_builderForToken.encodeABI();
								let gasPriceForToken = await web3.eth.getGasPrice();

								let gasLimitForToken = await web3.eth.estimateGas({
									gasPrice: web3.utils.toHex(gasPriceForToken),
									to: tokenContractAddress,
									from: from_address,
									chainId: chainId,
									data: encodedTxForToken
								});

								await web3.eth.sendTransaction({
									gasPrice: web3.utils.toHex(gasPriceForToken),
									gas: web3.utils.toHex(gasLimitForToken),
									to: tokenContractAddress,
									from: from_address,
									chainId: chainId,
									data: encodedTxForToken
								});
							}

							let getTokenBalance = await tokenContract.methods.balanceOf(from_address.toString()).call();
							
							var currentBal = parseInt(getTokenBalance) / 100000000;
							
							if (currentBal < NFTDetails.price) {
								setSpinLoader(0);
								setDialogOpen(false);                        
								Swal.fire({  text: 'Insufficient fund for transfer',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
								return false;
							}
							await trnasferNFT(contractAddress,tokenId, tokenValue,NFTDetails.id);
							
					} catch (error) {
						setSpinLoader(0);
						setDialogOpen(false);
						Swal.fire({  text: 'Something went wrong please try again.',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
						return false;
					}
					
					}
					 else {
							Swal.fire({  text: 'Please Connect to MetaMask.',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
							return false;
						}	
			}
         
    }

    const trnasferNFT = async (nftcontractAddress,tokenId, tokenPrice, item_id) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let web3 = new Web3(window.ethereum);
            try {
                let contractAddress = `${config.mainMarketplaceContract}`;
                let from_address = accounts[0];
                const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);
                let tx_builder = '';
                let itemPrice = 0;
                itemPrice = tokenPrice / 10 ** 18;
                
                tx_builder = await contract.methods.buy(nftcontractAddress.toString(), tokenId.toString(), tokenPrice.toString());
                let encoded_tx = tx_builder.encodeABI();
                let gasPrice = await web3.eth.getGasPrice();

                let gasLimit = await web3.eth.estimateGas({
                    gasPrice: web3.utils.toHex(gasPrice),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                const txData = await web3.eth.sendTransaction({
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    from: from_address,
                    data: encoded_tx
                });

                if (txData.transactionHash) {
                    var paymentArr = {
                        email: loginData?.email,
                        user_id: loginData?.id,
                        txHash: txData.transactionHash,
                        amount: itemPrice,
                        to_address: from_address,
                        owner_address: from_address,
                        item_id: item_id
                    }
                    buyItemAPI(paymentArr)
                } else {
                   Swal.fire({  text: 'Please try again.',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                    setSpinLoader(0);
                    setDialogOpen(false);
                    return false;
                }

            } catch (err) {
                console.log(err);
                if (err.message.toString().split('insufficient funds')[1]) {
                    Swal.fire({  text: 'Transaction reverted : insufficient funds',
						  title: "Alert",
						  confirmButtonText: 'Okay',
						  confirmButtonColor: '#e7275a',
						  background: '#343444',
						  color: '#FFF',
						  allowOutsideClick:false
						});
                } else {
                    if (err.toString().split('execution reverted:')[1]) {                        
                        Swal.fire({  text: 'Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0],
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
                    } else {
                       Swal.fire({  text: 'Please try again.',
									  title: "Alert",
									  confirmButtonText: 'Okay',
									  confirmButtonColor: '#e7275a',
									  background: '#343444',
									  color: '#FFF',
									  allowOutsideClick:false
									});
                    }
                }
                setSpinLoader(0);
                setDialogOpen(false);
                return false;
            }

        } else {
           Swal.fire({  text: 'Please Connect to MetaMask.',
				  title: "Alert",
				  confirmButtonText: 'Okay',
				  confirmButtonColor: '#e7275a',
				  background: '#343444',
				  color: '#FFF',
				  allowOutsideClick:false
				});
            setSpinLoader(0);
            setDialogOpen(false);
            return false;
        }
    }

    const buyItemAPI = async (data) => {
        let res = await buyItemAction(data);
        if (res.success) {
            Swal.fire({  text: res.msg,
					  title: "Alert",
					  confirmButtonText: 'Okay',
					  confirmButtonColor: '#e7275a',
					  background: '#343444',
					  color: '#FFF',
					  allowOutsideClick:false
					});
            setTimeout(() => {
                window.location.href = `${config.baseUrl}licenses-cities`;
            }, 2000);
        } else {
           Swal.fire({  text: 'Please try again.',
							  title: "Alert",
							  confirmButtonText: 'Okay',
							  confirmButtonColor: '#e7275a',
							  background: '#343444',
							  color: '#FFF',
							  allowOutsideClick:false
							});
        }
    }

    

   
    
    return (
       <div className='item-details'>
            <Toaster />
            <Header data={{'cartcount':cartcount}} />
            
            <div className="market-place pro-view-page">
<div className="container">
<div className="row">
  <div className="col-12 col-md-3 col-lg-2 px-0 ">
    <div className="left-sidebar">
     <ul>
		<li><Link to={`${config.baseUrl}marketplace`} className="">Weapon & Armor Collections</Link></li>
		<li><Link to={`${config.baseUrl}land-marketplace`} className="">Land</Link></li>
		<li className="active"><Link to={`${config.baseUrl}licenses-cities`} className="">License</Link></li>	
		<li><Link to={`${config.baseUrl}othernfts`} className="">Other NFTs</Link></li>	
		<li className="d-none"><Link to={`${config.baseUrl}mystery-loot-crates`} className="">Mystery Loot Crates</Link></li>	
	  </ul>
    </div>
  </div>
  <div className="col-12 col-md-9 col-lg-10 pl-4">
    <div className="row">
      <div className="col-12 back-btn">
      <button className="custom-btn btn-bg-blue" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i>Back</button>
     </div>
      <div className="col-12 col-md-6">
        <div className="pro-view">         
          <div className="pro-view-img">
          {NFTDetails?.image ?
			   <img src={`licenses/${NFTDetails.city_image_title}-premium.png`} alt="NFT Image" />
				:
				<img height="100%" width="100%" src="images/no-image.png" alt="NFT Image" />
			}
                   
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="pro-detail-box">
          <h2 className="title">{NFTDetails?.title}</h2>
          <div className="btn-group">
            <button className={isHistory === 0 ? "custom-btn btn-bg-blue active" :"custom-btn btn-bg-blue"} onClick={() => { HideHistory() }}>Info</button>
                            <button className={isHistory === 1 ? "custom-btn btn-bg-blue active" :"custom-btn btn-bg-blue"} onClick={() => { ShowHistory() }}>History</button>          
          </div>
          <div className="pro-detail-inner">
            {isHistory === 0 ?  
							<>
							 <div className="pro-detail">
                            <p>Created Date :</p>
                            <p>{NFTDetails?.datetime}</p>
                        </div>
                         <div className="pro-detail">
                            <p>Owned By       :</p>
                            <p>{NFTDetails?.owner_address ? 
								NFTDetails?.owner_address.toString().substring(0, 5) + '...' + NFTDetails?.owner_address.toString().substring(NFTDetails?.owner_address.length - 5) :''}</p>
                        </div>
                        <div className="pro-detail">
                            <p>Created By :</p>
                            <p>{NFTDetails?.creater_name}</p>
                        </div>
                        <div className="pro-detail">
							<p>Contract Address:</p>
							<p>{NFTDetails?.contractAddress ? 
							NFTDetails?.contractAddress.toString().substring(0, 5) + '...' + NFTDetails?.contractAddress.toString().substring(NFTDetails?.contractAddress.length - 5) :''}</p>
						</div>
						   <div className="pro-detail">
							<p>License Type  :</p>
							<p>Premium</p>
						  </div>
						   <div className="pro-detail royalty">
							<p>BUSINESS TYPE  :</p>
							<p>Weed Farm</p>
						  </div>
						   <div className="pro-detail">
							<p>District  :</p>
							<p>{NFTDetails?.city_name}</p>
						  </div>
						  <div className="pro-detail">
                            <p>Token ID         :</p>
                            <p>#{NFTDetails?.token_id}</p>
                        </div> 
                        <div className="pro-detail">
                            <p>Royalty          :</p>
                            <p>{NFTDetails?.royalty_percent}%</p>
                        </div>  
                        <div className="pro-detail">
                            <p>Royalty Fees          :</p>
                            <p>{parseFloat((NFTDetails?.royalty_percent / 100) * NFTDetails?.price).toLocaleString('en-US')} SIN</p>
                        </div>                         
                         <div className="pro-detail">
                            <p>License Key          :</p>
                            <p>{NFTDetails?.license_key}</p>
                        </div>
				</>
                        :
                        <>
                        {
							NFTHistory.map((item, index) => (
							<div className="pro-detail">
							<div className="col-sm-8">
								<div className="auther-info-div">
									 <div className="author-avatar">								
									{!item.user_profile || item.user_profile == null || item.user_profile == 'null' ?
										<img src="images/default-user-icon.jpg" alt="Owner Profile" className="avatar" />
										:
										<img src={`${config.imageUrl + item.user_profile}`} alt="Axies" className="avatar" />
									}
									
									</div>
										<div className="author-infor">
											<div className="name">
												<h6>
												{item?.user_address.toString().substring(0, 3) + '...' + item?.user_address.toString().substring(item?.user_address.length - 3)}
												</h6> <span> {item.transaction_type}</span>
											</div>
											<span className="time">{item.created_date}</span>
										</div>	
								</div>
								</div>
								<div className="col-sm-4 price text-right">
									<span> {parseFloat(item.amount).toLocaleString('en-US')} SIN</span>
								</div>
							</div>
							))
						}
                        </>
					}		  
          
         
          
          </div>
          <div className="price-box">
            <p><span>Price</span> <br />{parseInt(NFTDetails?.price).toLocaleString('en-US')} SIN</p> 
             {connectWalletAddress ?
				connectWalletAddress == NFTDetails.owner_address && NFTDetails.is_sold == 0 ?
				<>
					<i style={{ fontSize: '25px' }} class="fa fa-exclamation-circle" data-tip={`You are the owner of this NFT`} aria-hidden="true"></i>
					<ReactTooltip /> &nbsp;
				</> : ''
				: ''
			}
			  {loginData.id ?
				connectWalletAddress ?
					NFTDetails.is_sold === 1 || NFTDetails?.is_on_sale == 0 ?
						<p style={{ color: 'red' }}>Sold Out</p> :                                                    
							<button className="custom-btn" style={{ cursor: NFTDetails.is_sold === 1 ||  connectWalletAddress == NFTDetails.owner_address ? 'not-allowed' : '', background: NFTDetails.is_sold === 1 || connectWalletAddress == NFTDetails.owner_address ? 'gray' : '', }} disabled={spinLoader || NFTDetails.is_sold === 1 || connectWalletAddress == NFTDetails.owner_address || NFTDetails == 1 || NFTDetails?.is_on_sale == 0 ? true : false} onClick={() => validatelicense()} >
								{spinLoader == 1 ? 'Processing...' : `Buy for ${parseInt(NFTDetails?.price).toLocaleString('en-US')} SIN`}</button>                                                           
					:
					<button onClick={() => connectMetasmask()} className="custom-btn">Connect Wallet</button>
				:
				<a href={`${config.baseUrl}login`}>
					<button className="custom-btn">Login</button>
				</a>
			}

          </div>
          <div className="pro-description-text">
            <p>Description :</p>
            <p>{NFTDetails?.description}</p>
          </div>
        </div>
      </div>


</div>
</div>
</div>
</div>
</div>	
           
            <Footer />
        </div>
    );
}

export default BuyCoffeeLicense;
